import { Box, Divider, Paper, Typography } from '@mui/material';

/* Reusable paper that we'll put charts and other displays on */
export default function DashboardPaper(props) {
	const { title, PaperProps, BoxProps, children } = props;

	return (
		<Box display='flex' justifyContent='center'>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					// aspectRatio: 1,
					...PaperProps,
				}}
			>
				<Box p={1}>
					<Typography variant='h6'>{title}</Typography>
				</Box>
				<Divider />
				<Box {...BoxProps} flexGrow={1}>
					{children}
				</Box>
			</Paper>
		</Box>
	);
}

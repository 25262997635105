import {
	createTheme,
	Typography,
	ThemeProvider,
	CssBaseline,
	Box,
	Container,
	Paper,
	Divider,
	Button,
	ToggleButtonGroup,
	ToggleButton,
	Stack,
	ButtonGroup,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
} from '@mui/material';
import {
	DateTimeRangePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

import '@fontsource/montserrat';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import testdata from './assets/testdata.json';
import filterStatusData from './assets/FilterStatusTest.json';
import confidenceTestData from './assets/ConfidenceTest.json';
import hitsByCameraData from './assets/HitsByCamera.json';
import hitsByFilterData from './assets/HitsByFilter.json';
import userActivityData from './assets/UserActivity.json';
import ConfusionMatrix from './components/ConfusionMatrix/ConfusionMatrix';
import DashboardPaper from './components/DashboardPaper';
import ComparisonChart from './components/charts/ComparisonChart';
import React from 'react';
import CustomDateTimeRangePicker from './components/CustomDateTimeRangePicker';
import StackedBarChart from './components/charts/StackedBarChart';
import chroma from 'chroma-js';
import VerticalStackedBarChart from './components/charts/VerticalStackedBarChart';
import ContainedToggleButton from './components/ContainedToggleButton';
import CustomAreaChart from './components/charts/CustomAreaChart';

import { LicenseInfo } from '@mui/x-license-pro';
import ModelDetectComparisonChart from './components/charts/ModelDetectComparisonChart';
LicenseInfo.setLicenseKey(
	'54274d0762279a16fb3ee6f492da44dfTz05MTExMCxFPTE3NDgwMTU2MDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
/*
	This palette gives us our main colors and some additional options that hopefully keep things looking nice.
	We also set a contrastThreshold for text and create a custom scrollbar for our components.

	Some Notes (Chad):
	- I didn't want to step on the existing code too much so I made a new date range picker for trajectory estimation.
	- I also added a new state for the trajectory data and a placeholder for the fetch call.
	- This is a bit messy but I left the variables alone for Yolo metrics, so we can expect a default naming convention for the yolo data and a prefixed naming convention for the trajectory data.
	- SelectModel dictates which section is shown, so we can add more models and sections as needed.
*/
const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#ffc107',
		},
		secondary: {
			main: '#bdbdbd',
		},
		complementary: {
			main: '#8307ff',
			light: '#9c38ff',
			dark: '#5b04b2',
		},
		analogous: {
			main: '#ffea00',
			light: '#ffee33',
			dark: '#b2a300',
		},
		contrastThreshold: 4.5,
	},
	typography: {
		fontFamily: 'Montserrat',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					'*::-webkit-scrollbar': {
						width: '15px',
					},
					'*::-webkit-scrollbar-thumb': {
						borderRadius: '10px',
						border: '3px solid rgba(0, 0, 0, 0.00)',
						background: 'rgba(255, 255, 255, 0.3)',
						WebkitBackgroundClip: 'padding-box',
						MozBackgroundClip: 'padding',
						backgroundClip: 'padding-box',
					},
					'*::-webkit-scrollbar-thumb:hover': {
						border: '2px solid rgba(0, 0, 0, 0.00)',
						backgroundColor: 'rgba(255, 255, 255, 0.4)',
					},
				},
			},
		},
	},
});

export default function App() {
	// Global
	const availableModels = ['YOLOv6/9', 'Trajectory Estimation'];
	const [selectedModel, setSelectedModel] = React.useState(availableModels[0]);
	const currentTime = dayjs(new Date());
	const defaultRange = [
		dayjs(new Date()).subtract(6, 'hour'),
		dayjs(new Date()),
	];
	const [unconfirmedDateTimeRange, setUnconfirmedDateTimeRange] =
		React.useState(defaultRange);

	// Trajectory Estimation
	const [trajectoryDateTimeRange, setTrajectoryDateTimeRange] =
		React.useState(defaultRange);
	const [incidentData, setIncidentData] = React.useState([]);
	const [incidentNameManifest, setIncidentNameManifest] = React.useState([]);
	const [selectedIncidentData, setSelectedIncidentData] = React.useState(null);
	const [trajectoryData, setTrajectoryData] = React.useState([
		{
			time: currentTime.format('HH:mm MMM D, YYYY'),
			AcresBurned: [0, 0],
			Estimation: 0,
		},
		{
			time: currentTime.add(1, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 10,
			Estimation: 10,
		},
		{
			time: currentTime.add(2, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 20,
			Estimation: 20,
		},
		{
			time: currentTime.add(3, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 20,
			Estimation: 30,
		},
		{
			time: currentTime.add(4, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 20,
			Estimation: 40,
		},
		{
			time: currentTime.add(5, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 50,
			Estimation: 70,
		},
		{
			time: currentTime.add(6, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 60,
			Estimation: 90,
		},
		{
			time: currentTime.add(7, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 70,
			Estimation: 100,
		},
		{
			time: currentTime.add(8, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 80,
			Estimation: 150,
		},
		{
			time: currentTime.add(9, 'day').format('HH:mm MMM D, YYYY'),
			AcresBurned: 90,
			Estimation: 200,
		},
	]);

	// Yolov6/9
	const [dateTimeRange, setDateTimeRange] = React.useState([
		dayjs(new Date()).subtract(6, 'hour'),
		dayjs(new Date()),
	]);
	const [dataMode, setDataMode] = React.useState('range');
	const [data, setData] = React.useState();

	// fetches metric data from the AlertWest API for YOLOv6/9
	React.useEffect(() => {
		if (selectedModel.indexOf('YOLO') === -1) return;
		const getData = async () => {
			await fetch(
				'https://t1.alertwest.com/api/metrics?' +
					new URLSearchParams({
						startEpoch: dayjs(dateTimeRange[0]).unix(),
						endEpoch: dayjs(dateTimeRange[1]).unix(),
					}).toString()
			)
				.then((response) => response.json())
				.then((response) => {
					const data = {
						metricData: [
							{
								name: 'Precision',
								data: [
									{
										model: 'YOLOv6',
										value: Number(response.precision_yolov6).toFixed(3),
									},
									{
										model: 'YOLOv9',
										value: Number(response.precision_yolov9).toFixed(3),
									},
								],
							},
							{
								name: 'Recall',
								data: [
									{
										model: 'YOLOv6',
										value: Number(response.recall_yolov6).toFixed(3),
									},
									{
										model: 'YOLOv9',
										value: Number(response.recall_yolov9).toFixed(3),
									},
								],
							},
							{
								name: 'F1 Score',
								data: [
									{
										model: 'YOLOv6',
										value: Number(response['f1-score_yolov6']).toFixed(3),
									},
									{
										model: 'YOLOv9',
										value: Number(response['f1-score_yolov9']).toFixed(3),
									},
								],
							},
							{
								name: 'AP',
								data: [
									{
										model: 'YOLOv6',
										value: Number(response.AP_yolov6).toFixed(3),
									},
									{
										model: 'YOLOv9',
										value: Number(response.AP_yolov9).toFixed(3),
									},
								],
							},
							{
								name: 'MAp',
								data: [
									{
										model: 'YOLOv6',
										value: Number(response.MAp_yolov6).toFixed(3),
									},
									{
										model: 'YOLOv9',
										value: Number(response.MAp_yolov9).toFixed(3),
									},
								],
							},
						],
						matrixData: [
							{
								model: 'YOLOv6',
								tpCount: response.yolov6.TP_count,
								fpCount: response.yolov6.FP_count,
								tnCount: response.yolov6.TN_count,
								fnCount: response.yolov6.FN_count,
							},
							{
								model: 'YOLOv9',
								tpCount: response.yolov9.TP_count,
								fpCount: response.yolov9.FP_count,
								tnCount: response.yolov9.TN_count,
								fnCount: response.yolov9.FN_count,
							},
						],
						v6Detects: response.total_v6_detects,
						v9Detects: response.total_v9_detects,
						matchingDetects: response.matching_detects,
						totalDetects: response.total_detections,
						models: ['YOLOv6', 'YOLOv9'],
					};
					setData(data);
				});
		};

		getData();
		//console.log("skipping getData due to YOLOvX API issues - remove the comment above to re-enable");
	}, [dateTimeRange, selectedModel]);

	// get incident name manifest before getting calfire data
	React.useEffect(() => {
		if (selectedModel.indexOf('Trajectory') === -1) return;
		(async () => {
			await fetch(
				'https://e7i8uj9qi2.execute-api.us-west-2.amazonaws.com/prod/listIncidents'
			)
				.then((response) => response.json())
				.then((response) => {
					setIncidentNameManifest(response);
				});
		})();
	}, [selectedModel]);

	// once inci name manifest is fetched, fetch incident data from the CalFire API then filters out incidents not in the manifest
	React.useEffect(() => {
		if (selectedModel.indexOf('Trajectory') === -1 || !incidentNameManifest)
			return;
		(async () => {
			const url =
				'https://incidents.fire.ca.gov/umbraco/api/IncidentApi/List?inactive=true';
			await fetch(url)
				.then((response) => response.json())
				.then((response) => {
					const incidentData = {};
					response
						.filter((incident) => incident.Name !== null)
						.sort((a, b) => a.Name.localeCompare(b.Name))
						.forEach((incident) => {
							incidentData[incident.Name] = incident;
						});

					/* Mock Data
					Name: "Test Fire",
					AcresBurned: 100,
					PercentContained: 10,
					Started: "2021-10-01T00:00:00",
					Updated: "2021-10-01T00:00:00"
					*/

					incidentData['Test Fire'] = {
						Name: 'Test Fire',
						AcresBurned: 100,
						PercentContained: 10,
						Started: '2021-10-01T00:00:00',
						Updated: '2021-10-01T00:00:00',
					};

					Object.keys(incidentData).forEach((incident) => {
						const incidentName = incidentData[incident].Name;
						const found = incidentNameManifest.find(
							(element) => element.incident_name.trim() === incidentName.trim()
						);
						if (!found) {
							delete incidentData[incident];
						}
					});
					setIncidentData(incidentData);
					// only preselect the first incident if there is data
					if (Object.keys(incidentData).length > 0) {
						setSelectedIncidentData(incidentData[Object.keys(incidentData)[0]]);
					}
				});
		})();
	}, [selectedModel, incidentNameManifest]);

	// fetches model metrics from the AlertWest API for Trajectory Estimation
	React.useEffect(() => {
		if (selectedModel.indexOf('Trajectory') === -1) return;
		if (!selectedIncidentData || !trajectoryDateTimeRange) return;
		const getData = async () => {
			// start_epoch, end_epoch, incident_name
			const url =
				'https://e7i8uj9qi2.execute-api.us-west-2.amazonaws.com/prod/getResults' +
				'?start_epoch=' +
				trajectoryDateTimeRange[0].unix() +
				'&end_epoch=' +
				trajectoryDateTimeRange[1].unix() +
				'&incident_name=' +
				selectedIncidentData.Name;
			await fetch(url)
				.then((response) => response.json())
				.then((response) => {
					// expects {"ground_truth": [...], "model_output": [...]}
					let data = [];
					response.ground_truth.forEach((value, index) => {
						data.push({
							time: dayjs.unix(value[0]).format('HH:mm MMM D, YYYY'),
							AcresBurned: value[1],
							Estimation: response.model_output[index][1],
						});
					});
					setTrajectoryData(data);
				});
		};
		getData();
	}, [selectedModel, selectedIncidentData, trajectoryDateTimeRange]);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Container maxWidth='xl'>
				<Typography variant='h4' pt={1} gutterBottom>
					AI Dashboard - {selectedModel}
				</Typography>

				{/* Model Selection */}
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<ButtonGroup
						className={'clean-toggle-group'}
						variant='contained'
						aria-label='Basic button group'
					>
						{availableModels.map((model) => (
							<Button
								key={model}
								variant={selectedModel === model ? 'contained' : 'outlined'}
								onClick={() => setSelectedModel(model)}
							>
								{model}
							</Button>
						))}
					</ButtonGroup>
				</Stack>

				{/* Trajectory Estimation Section */}
				{selectedModel === 'Trajectory Estimation' && incidentData && (
					<Stack useFlexGap alignItems='center' gap={2}>
						<Stack direction='row' spacing={2} alignItems='center' width='100%'>
							{/* Incidents List */}
							<FormControl sx={{ minWidth: 200 }}>
								<InputLabel id='incident-select-label'>Incident</InputLabel>
								<Select
									labelId='incident-select-label'
									id='incident-select'
									label='Incident'
									value={selectedIncidentData ? selectedIncidentData.Name : ''}
									onChange={(e) => {
										const selectedName = e.target.value;
										setSelectedIncidentData(incidentData[selectedName]);
										console.log(
											'selected incident: ',
											incidentData[selectedName]
										);
									}}
								>
									{Object.keys(incidentData).map((incident) => (
										<MenuItem key={incident} value={incident}>
											{incident}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							{/* Custom DateTime Range Picker */}
							<CustomDateTimeRangePicker
								dateTimeRange={trajectoryDateTimeRange}
								setDateTimeRange={setTrajectoryDateTimeRange}
								unconfirmedDateTimeRange={unconfirmedDateTimeRange}
								setUnconfirmedDateTimeRange={setUnconfirmedDateTimeRange}
								disabled={dataMode !== 'range'}
							/>
						</Stack>

						{/* Chart */}

						{selectedIncidentData && (
							<DashboardPaper
								title={selectedIncidentData.Name}
								BoxProps={{
									pt: 1,
									pr: 2,
									height: '50vh',
									width: '78vw',
									backgroundColor: '#fff',
								}}
							>
								{trajectoryData && (
									<>
										<CustomAreaChart
											data={trajectoryData}
											xKey='time'
											yAxisLabel='Acres'
											xAxisLabel='Date'
											areas={[
												{
													dataKey: 'AcresBurned',
													name: 'CalFire Dataset',
													stroke: '#ff0000',
													fill: '#ff0000',
													fillOpacity: 0.5,
												},
												{
													dataKey: 'Estimation',
													name: 'Our Estimation',
													stroke: '#0000ff',
													fill: '#0000ff',
													fillOpacity: 0.5,
												},
											]}
										/>
										<Box mt={4}>
											<Stack direction='row' spacing={2}>
												<Box>
													<strong>Started</strong>:{' '}
													{selectedIncidentData.Started}
												</Box>
												<Box>
													<strong>Updated</strong>:{' '}
													{selectedIncidentData.Updated}
												</Box>
												<Box>
													<strong>Acres Burned</strong>:{' '}
													{selectedIncidentData.AcresBurned.toLocaleString(
														'en-US'
													)}
												</Box>
												<Box>
													<strong>Containment</strong>:{' '}
													{selectedIncidentData.PercentContained}%
												</Box>
											</Stack>
										</Box>
									</>
								)}
							</DashboardPaper>
						)}
					</Stack>
				)}

				{/* Yolov6/9 Date Range Section*/}
				{selectedModel.indexOf('YOLO') > -1 && (
					<Stack
						direction='row'
						// justifyContent='space-between'
						alignItems='center'
						gap={2}
					>
						<ToggleButtonGroup
							color='primary'
							value={dataMode}
							exclusive
							onChange={(e) => setDataMode(e.target.value)}
							aria-label='Data Mode'
						>
							<ContainedToggleButton
								current={dataMode}
								value='live'
								color='primary'
								ButtonProps={{ disabled: true }}
							>
								Live
							</ContainedToggleButton>
							<ContainedToggleButton
								current={dataMode}
								value='range'
								color='primary'
							>
								Time Range
							</ContainedToggleButton>
						</ToggleButtonGroup>
						<Box display='flex'>
							<CustomDateTimeRangePicker
								dateTimeRange={dateTimeRange}
								setDateTimeRange={setDateTimeRange}
								unconfirmedDateTimeRange={unconfirmedDateTimeRange}
								setUnconfirmedDateTimeRange={setUnconfirmedDateTimeRange}
								disabled={dataMode !== 'range'}
							/>
						</Box>
					</Stack>
				)}

				{/* Yolov6/9 Metrics Section */}
				{data && (
					<Grid container columns={24} spacing={2}>
						{selectedModel.indexOf('YOLO') > -1 &&
							data &&
							data.metricData.map((metric) => (
								<Grid xs={24} sm={12} md={8} lg={6}>
									<DashboardPaper
										key={`${metric.name}-chart`}
										title={metric.name}
										BoxProps={{ pt: 1, pr: 2, height: '280px' }}
									>
										<ComparisonChart
											startColor={theme.palette.secondary.dark}
											endColor={theme.palette.primary.main}
											data={metric.data}
											models={data.models}
										/>
									</DashboardPaper>
								</Grid>
							))}
						{data && (
							<Grid xs='auto'>
								<DashboardPaper
									title='Detections by Model'
									BoxProps={{ pt: 1, pr: 2, height: '280px' }}
								>
									{/* <Box display='flex' height='100%' gap={1}>
										<Box flex={1}> */}
									<ModelDetectComparisonChart
										data={data}
										colors={[
											theme.palette.primary.main,
											theme.palette.secondary.dark,
										]}
									/>
									{/* </Box>
										<Stack flex={1} gap={1}>
											
										</Stack> */}
									{/* </Box> */}
								</DashboardPaper>
							</Grid>
						)}
						{/* {data &&
						data.matrixData.map((matrix) => (
							<Grid sm={24} md={12} lg={9}>
								<DashboardPaper
									title={`${matrix.model} Confusion Matrix`}
									BoxProps={{ pt: 1, pl: 1, pr: 2, pb: 2, height: '280px' }}
								>
									<ConfusionMatrix
										trueNegativeCount={matrix.tnCount}
										falsePositiveCount={matrix.fpCount}
										falseNegativeCount={matrix.fnCount}
										truePositiveCount={matrix.tpCount}
										highColor={theme.palette.primary.main}
										lowColor='#121212'
									/>
								</DashboardPaper>
							</Grid>
						))} */}
						{/* <Grid sm={24} md={12} lg={9}>
						<DashboardPaper
							title='Confusion Matrix Placeholder Title'
							BoxProps={{ pt: 1, pl: 1, pr: 2, pb: 2, height: '280px' }}
						>
							<ConfusionMatrix
								trueNegativeCount={75}
								falsePositiveCount={25}
								falseNegativeCount={45}
								truePositiveCount={55}
								highColor={theme.palette.primary.main}
								lowColor='#121212'
							/>
						</DashboardPaper>
					</Grid>
					<Grid sm={24} md={12} lg={9}>
						<DashboardPaper
							title='Confusion Matrix Placeholder Title'
							BoxProps={{ pt: 1, pl: 1, pr: 2, pb: 2, height: '280px' }}
						>
							<ConfusionMatrix
								trueNegativeCount={518}
								falsePositiveCount={61}
								falseNegativeCount={815}
								truePositiveCount={623}
								highColor={theme.palette.primary.main}
								lowColor='#121212'
							/>
						</DashboardPaper>
					</Grid> */}
						{/* <Grid xs={12}>
						<Divider />
					</Grid> */}
						{/* <Grid xs={12} sm={6} md={3}>
						<DashboardPaper
							title='AI Hits by Filter Status'
							PaperProps={{ height: '350px' }}
						>
							<StackedBarChart
								data={filterStatusData}
								categoryDataKey='time'
								showTooltip
								showLegend
								colorRange={[
									theme.palette.secondary.dark,
									'#f7f7f7',
									theme.palette.primary.main,
								]}
							/>
						</DashboardPaper>
					</Grid> */}
						{/* <Grid xs={12} sm={6} md={3}>
						<DashboardPaper
							title='AI Hits by Confidence'
							PaperProps={{ height: '350px' }}
						>
							<StackedBarChart
								data={confidenceTestData}
								categoryDataKey='time'
								showTooltip
								colorRange={[
									theme.palette.complementary.dark,
									theme.palette.primary.main,
								]}
							/>
						</DashboardPaper>
					</Grid> */}
						{/* <Grid xs={12} sm={6} md={3}>
						<DashboardPaper
							title='Top AI Hits by Camera'
							PaperProps={{ height: '350px' }}
						>
							<VerticalStackedBarChart
								data={hitsByCameraData}
								categoryDataKey='cam'
								vertical
								barCategoryGap={0}
								colorRange={[
									theme.palette.secondary.dark,
									theme.palette.primary.main,
								]}
							/>
						</DashboardPaper>
					</Grid> */}
						{/* <Grid xs={12} sm={6} md={3}>
						<DashboardPaper
							title='Top AI Hits by Filter'
							PaperProps={{ height: '350px' }}
						>
							<VerticalStackedBarChart
								data={hitsByFilterData}
								categoryDataKey='filter'
								vertical
								barCategoryGap={0}
								colorRange={[
									theme.palette.secondary.dark,
									theme.palette.primary.main,
								]}
							/>
						</DashboardPaper>
					</Grid> */}
						{/* <Grid xs={12} sm={6} md={4}>
						<DashboardPaper
							title='User Activity'
							PaperProps={{ height: '350px' }}
						>
							<VerticalStackedBarChart
								data={userActivityData}
								categoryDataKey='user'
								vertical
								barCategoryGap={0}
								colorRange={[
									theme.palette.secondary.dark,
									theme.palette.complementary.light,
									theme.palette.complementary.dark,
									theme.palette.primary.main,
									theme.palette.primary.dark,
								]}
								showLegend
							/>
						</DashboardPaper>
					</Grid> */}
					</Grid>
				)}
			</Container>
		</ThemeProvider>
	);
}

import {
	DateTimeRangePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro';

import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

export default function CustomDateTimeRangePicker(props) {
	const {
		dateTimeRange,
		setDateTimeRange,
		unconfirmedDateTimeRange,
		setUnconfirmedDateTimeRange,
		disabled,
	} = props;

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateTimeRangePicker
				value={unconfirmedDateTimeRange}
				disabled={disabled}
				disableFuture
				onAccept={(newValue) => {
					setDateTimeRange(newValue);
				}}
				onChange={(newValue) => setUnconfirmedDateTimeRange(newValue)}
				ampm={false}
				localeText={{ start: 'Start Time', end: 'End Time' }}
				slotProps={{
					actionBar: {
						actions: [],
					},
					shortcuts: {
						items: [
							{
								label: 'Month to Date',
								getValue: () => {
									const today = dayjs();
									return [today.startOf('month'), today];
								},
							},
							{
								label: 'Week to Date',
								getValue: () => {
									const today = dayjs();
									return [today.startOf('week'), today];
								},
							},
							{
								label: 'Last Week',
								getValue: () => {
									const today = dayjs();
									const prevWeek = today.subtract(7, 'day');
									return [prevWeek.startOf('week'), prevWeek.endOf('week')];
								},
							},
							{
								label: 'Last 7 Days',
								getValue: () => {
									const today = dayjs();
									return [today.subtract(7, 'day'), today];
								},
							},
							{
								label: 'Today',
								getValue: () => {
									const today = dayjs();
									return [today, today];
								},
							},
							{
								label: 'Last 6 Hours',
								getValue: () => [
									dayjs(new Date()).subtract(6, 'hour'),
									dayjs(new Date()),
								],
							},
						],
					},
				}}
				sx={{ py: 2 }}
			/>
		</LocalizationProvider>
	);
}

import { Box, Divider, Paper, Typography } from '@mui/material';
import chroma from 'chroma-js';
import ConfusionMatrixCell from './ConfusionMatrixCell';
import ConfusionMatrixLegend from './ConfusionMatrixLegend';
import Grid from '@mui/material/Unstable_Grid2';

export default function ConfusionMatrix(props) {
	const {
		trueNegativeCount,
		falsePositiveCount,
		falseNegativeCount,
		truePositiveCount,
		highColor,
		lowColor,
	} = props;
	/* Automatically grade cell colors based on the value */
	const colorScale = chroma.scale([lowColor, highColor]);

	return (
		<table
			style={{
				borderCollapse: 'collapse',
				textAlign: 'center',
				height: '1px',
				width: '100%',
			}}
		>
			<tbody>
				<tr>
					<th colSpan={2} rowSpan={2}></th>
					<th colSpan={2}>Predicted</th>
					<th colSpan={2} rowSpan={2}></th>
				</tr>
				<tr>
					<th colSpan={2}>
						<Grid container columns={2}>
							<Grid xs={1}>
								<Typography>
									<b>0</b>
								</Typography>
							</Grid>
							<Grid xs={1}>
								<Typography>
									<b>1</b>
								</Typography>
							</Grid>
						</Grid>
					</th>
				</tr>
				<tr>
					<th
						rowSpan={2}
						style={{
							writingMode: 'vertical-lr',
							transform: 'rotate(180deg)',
						}}
					>
						Actual
					</th>
					<th style={{ paddingLeft: '5px', paddingRight: '5px' }}>0</th>
					<td colSpan={2} rowSpan={2}>
						<Grid container columns={2} border='1px solid #fff'>
							<Grid xs={1}>
								<ConfusionMatrixCell
									label='True Negative'
									cellCount={trueNegativeCount}
									rowTotal={trueNegativeCount + falsePositiveCount}
									colorScale={colorScale}
									tdStyleProps={{
										borderLeft: '1px solid #fff',
										borderTop: '1px solid #fff',
									}}
								/>
							</Grid>
							<Grid xs={1}>
								<ConfusionMatrixCell
									label='False Positive'
									cellCount={falsePositiveCount}
									rowTotal={trueNegativeCount + falsePositiveCount}
									colorScale={colorScale}
									tdStyleProps={{
										borderRight: '1px solid #fff',
										borderTop: '1px solid #fff',
									}}
								/>
							</Grid>
							<Grid xs={1}>
								<ConfusionMatrixCell
									label='False Negative'
									cellCount={falseNegativeCount}
									rowTotal={falseNegativeCount + truePositiveCount}
									colorScale={colorScale}
									tdStyleProps={{
										borderLeft: '1px solid #fff',
										borderBottom: '1px solid #fff',
									}}
								/>
							</Grid>
							<Grid xs={1}>
								<ConfusionMatrixCell
									label='True Positive'
									cellCount={truePositiveCount}
									rowTotal={falseNegativeCount + truePositiveCount}
									colorScale={colorScale}
									tdStyleProps={{
										borderRight: '1px solid #fff',
										borderBottom: '1px solid #fff',
									}}
								/>
							</Grid>
						</Grid>
					</td>
					<td rowSpan={2} style={{ paddingLeft: '10px' }}>
						<ConfusionMatrixLegend lowColor={lowColor} highColor={highColor} />
					</td>
				</tr>
				<tr>
					<th style={{ paddingLeft: '5px', paddingRight: '0.5em' }}>1</th>
				</tr>
			</tbody>
		</table>
	);
}

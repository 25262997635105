import { Paper, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

/* Horizontal bar chart for displaying grouped data. Data within a group will have distinct colors that are shared between groups. */
export default function ComparisonChart(props) {
	const { startColor, endColor, data, models } = props;
	// console.log(data, models);
	const theme = useTheme();

	/* Tooltip that displays category and value */
	const CustomTooltip = ({ active, payload }) => {
		// console.log(payload);
		return (
			active &&
			payload && (
				<Paper variant='outlined' sx={{ p: 1 }}>
					<Typography fontWeight={600}>{payload[0].payload.model}</Typography>
					<Typography>{payload[0].payload.value}</Typography>
				</Paper>
			)
		);
	};

	/* Automatically grade bar colors based on the given start and end colors and the amount of data in each group */
	const barColors = chroma.scale([startColor, endColor]).colors(models.length);

	return (
		<ResponsiveContainer height='100%'>
			<BarChart data={data} margin={{ top: 17 }}>
				<CartesianGrid vertical={false} strokeDasharray='3 3' />
				<Tooltip
					content={CustomTooltip}
					cursor={{ fill: 'rgba(0,0,0,0.25)' }}
				/>
				<XAxis dataKey='model' tick={{ fill: '#fff' }} />
				<YAxis tick={{ fill: '#fff' }} />
				<Bar
					dataKey='value'
					label={{
						position: 'top',
						fill: '#fff',
					}}
					activeBar={<Rectangle stroke='#fff' strokeWidth={2} />}
				>
					{data.map((entry, index) => (
						<Cell key={`${entry.model}-cell`} fill={barColors[index]} />
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}

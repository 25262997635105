import { ToggleButton, useTheme } from '@mui/material';

/* Just a styled way of creating a ToggleButton */
export default function ContainedToggleButton(props) {
	const { current, value, color, ButtonProps, ButtonStyleProps } = props;
	const theme = useTheme();

	return (
		<ToggleButton
			value={value}
			{...ButtonProps}
			sx={{
				backgroundColor:
					current === value ? `${theme.palette[color].main} !important` : '',
				color:
					current === value
						? `${theme.palette.getContrastText(
								theme.palette[color].main
						  )} !important`
						: '',
				':hover': {
					backgroundColor:
						current === value ? `${theme.palette[color].dark} !important` : '',
				},
				...ButtonStyleProps,
			}}
		>
			{props.children}
		</ToggleButton>
	);
}

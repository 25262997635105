import { Box, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

export default function ModelDetectComparisonChart(props) {
	const { data, colors } = props;

	const [chartData, setChartData] = useState();
	const [chartWidth, setChartWidth] = useState(0);
	const infoRef = useRef(null);

	/* Tooltip that displays category and value */
	const CustomTooltip = ({ active, payload }) => {
		return (
			active &&
			payload && (
				<Paper variant='outlined' sx={{ p: 1 }}>
					{Object.entries(payload[0].payload).map((row, index) =>
						index === 0 ? (
							<Typography fontWeight={600} key={`tooltip-${row[1]}`}>
								{row[1]}
							</Typography>
						) : (
							<Typography key={`tooltip-${row[0]}`}>
								{row[0]}: <b>{row[1]}</b>
							</Typography>
						)
					)}
				</Paper>
			)
		);
	};

	useEffect(() => {
		if (!infoRef.current) return;

		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setChartWidth(entry.contentRect.width);
			}
		});

		observer.observe(infoRef.current);

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		setChartData([
			{
				model: 'YOLOv6',
				Matching: data.matchingDetects,
				Unique: data.v6Detects - data.matchingDetects,
			},
			{
				model: 'YOLOv9',
				Matching: data.matchingDetects,
				Unique: data.v9Detects - data.matchingDetects,
			},
		]);
	}, [data]);

	return (
		<Box display='flex' height='100%' gap={1}>
			<ResponsiveContainer width={chartWidth} height='100%'>
				<BarChart data={chartData}>
					<CartesianGrid vertical={false} strokeDasharray='3 3' />
					<Tooltip
						content={CustomTooltip}
						cursor={{ fill: 'rgba(0,0,0,0.25)' }}
					/>
					<XAxis dataKey='model' tick={{ fill: '#fff' }} />
					<YAxis tick={{ fill: '#fff' }} />
					<Bar
						dataKey='Matching'
						fill={colors[0]}
						activeBar={<Rectangle stroke='#fff' />}
						stackId='a'
					/>
					<Bar
						dataKey='Unique'
						fill={colors[1]}
						activeBar={<Rectangle stroke='#fff' />}
						stackId='a'
					/>
				</BarChart>
			</ResponsiveContainer>
			{/* <Box ref={infoRef} pb={4}> */}
			<Stack ref={infoRef} gap={1} justifyContent='flex-end' pb={4}>
				<Typography variant='caption'>
					YOLOv9 Verified Detects:{' '}
					<strong>
						{data.matchingDetects}/{data.v6Detects} (
						{Math.round((data.matchingDetects / data.v6Detects) * 100)}
						%)
					</strong>
				</Typography>
				<Typography variant='caption'>
					YOLOv9 Verified Misses:{' '}
					<strong>
						{data.v6Detects - data.matchingDetects} (
						{Math.round(
							((data.v6Detects - data.matchingDetects) / data.v6Detects) * 100
						)}
						%)
					</strong>
				</Typography>
				<Typography variant='caption'>
					Unique YOLOv9 Hits:{' '}
					<strong>{data.v9Detects - data.matchingDetects}</strong>
				</Typography>
			</Stack>
			{/* </Box> */}
		</Box>
	);
}

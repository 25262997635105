import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';

export default function CustomAreaChart({
  data,
  xKey,
  areas = [],
  yAxisLabel = 'Value',
  xAxisLabel = 'X-Axis',
  width = '100%',
  height = '100%',
  margin = { top: 20, right: 20, bottom: 20, left: 40 },
  gridStroke = '#ccc',
  tooltipFormatter = (value) =>
    Array.isArray(value) ? `Start: ${value[0]} - End: ${value[1]}` : value,
  legendProps = { verticalAlign: 'top', height: 36 },
  xAxisProps = {},
  yAxisProps = {},
  cartesianGridProps = {}
}) {
  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={data} margin={margin}>
        <CartesianGrid stroke={gridStroke} {...cartesianGridProps} />
        <XAxis
          dataKey={xKey}
          label={{ value: xAxisLabel, position: 'insideBottom', offset: -5 }}
          interval={0}
          angle={-20}
          {...xAxisProps}
        />
        <YAxis
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'insideLeft',
            offset: 10
          }}
          {...yAxisProps}
        />
        <Tooltip formatter={tooltipFormatter} />
        <Legend {...legendProps} />
        {areas.map((area, index) => (
          <Area
            key={index}
            type={area.type || 'linear'}
            dataKey={area.dataKey}
            name={area.name}
            stroke={area.stroke || '#8884d8'}
            fill={area.fill || '#8884d8'}
            fillOpacity={area.fillOpacity || 0.3}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}
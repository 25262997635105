import { Paper, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

export default function StackedBarChart(props) {
	const {
		data,
		categoryDataKey,
		barCategoryGap,
		showTooltip,
		showLegend,
		colorRange,
	} = props;
	const theme = useTheme();

	/* Automatically grade bar colors based on the given start and end colors and the amount of data in each group */
	const barColors = chroma
		.scale(colorRange)
		.colors(Object.keys(data[0]).length - 1);

	const CustomTooltip = ({ active, payload }) => {
		return (
			active &&
			payload && (
				<Paper variant='outlined' sx={{ p: 1 }}>
					{Object.entries(payload[0].payload).map((row, index) =>
						index === 0 ? (
							<Typography fontWeight={600} key={`tooltip-${row[1]}`}>
								{row[1]}
							</Typography>
						) : (
							<Typography key={`tooltip-${row[0]}`}>
								{row[0]}: <b>{row[1]}</b>
							</Typography>
						)
					)}
				</Paper>
			)
		);
	};

	return (
		<ResponsiveContainer width='100%' height='100%'>
			<BarChart data={data} barCategoryGap={barCategoryGap}>
				{showTooltip && (
					<Tooltip
						content={CustomTooltip}
						cursor={{ fill: 'rgba(0,0,0,0.0)' }}
					/>
				)}
				{showLegend && <Legend />}
				{Object.keys(data[0]).map((attribute, index) =>
					attribute !== categoryDataKey ? (
						<Bar
							dataKey={attribute}
							fill={barColors[index - 1]}
							activeBar={<Rectangle stroke='#fff' />}
							stackId='a'
						/>
					) : (
						''
					)
				)}
				<XAxis hide />
				<YAxis hide />
			</BarChart>
		</ResponsiveContainer>
	);
}

import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

export default function VerticalStackedBarChart(props) {
	const {
		data,
		categoryDataKey,
		barCategoryGap,
		showTooltip,
		showLegend,
		colorRange,
	} = props;
	const [width, setWidth] = React.useState(300);
	const theme = useTheme();

	const barColors = chroma
		.scale(colorRange)
		.colors(Object.keys(data[0]).length - 1);

	const CustomTooltip = ({ active, payload }) => {
		return (
			active &&
			payload && (
				<Paper variant='outlined' sx={{ p: 1 }}>
					{Object.entries(payload[0].payload).map((row, index) =>
						index === 0 ? (
							<Typography fontWeight={600} key={`tooltip-${row[1]}`}>
								{row[1]}
							</Typography>
						) : (
							<Typography key={`tooltip-${row[0]}`}>
								{row[0]}: <b>{row[1]}</b>
							</Typography>
						)
					)}
				</Paper>
			)
		);
	};

	/* Display text over rows with link styling. */
	const CustomTick = (props) => {
		return (
			<g transform={`translate(${props.x},${props.y})`}>
				<foreignObject
					width={width - 72}
					height={props.height}
					x={0}
					y={-12}
					style={{
						textShadow:
							'-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
						color: theme.palette.analogous.main,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					<text
						style={{ cursor: 'pointer' }}
						onMouseEnter={(e) => {
							e.target.style.textDecoration = 'underline';
							e.target.style.textShadow = 'none';
						}}
						onMouseLeave={(e) => {
							e.target.style.textDecoration = 'none';
							e.target.style.textShadow =
								'-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000';
						}}
					>
						{props.payload.value}
					</text>
				</foreignObject>
			</g>
		);
	};

	const renderCustomizedLabel = (props) => {
		return (
			<text
				x={width - 12}
				y={props.y + props.height / 2 + props.offset}
				fill='#fff'
				textAnchor='end'
				style={{
					textShadow:
						'-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
				}}
			>
				{props.value}
			</text>
		);
	};

	/* This allows us to basically treat bars as links at some point. */
	const handleBarClick = (e) => {
		if (e?.activePayload) {
			console.log(e);
		}
	};

	return (
		<ResponsiveContainer
			width='100%'
			height='100%'
			onResize={(width) => setWidth(width)}
		>
			<BarChart
				data={data}
				onClick={handleBarClick}
				layout='vertical'
				barCategoryGap={barCategoryGap}
				// style={{ cursor: 'pointer' }}
			>
				{showTooltip && <Tooltip content={CustomTooltip} />}
				{showLegend && <Legend />}
				{Object.entries(data[0]).map((attribute, index) =>
					attribute[0] !== categoryDataKey ? (
						<Bar
							dataKey={attribute[0]}
							fill={barColors[index - 1]}
							activeBar={<Rectangle />}
							stackId='a'
						>
							{index === Object.entries(data[0]).length - 1 ? (
								<LabelList
									content={(props) => renderCustomizedLabel(props)}
									// position='end'
									// valueAccessor={(entry) => entry.value[1]}
								/>
							) : null}
						</Bar>
					) : null
				)}
				<Tooltip content={CustomTooltip} cursor={{ fill: 'rgba(0,0,0,0.25' }} />
				<XAxis type='number' hide />
				<YAxis
					axisLine={false}
					tickLine={false}
					dataKey={categoryDataKey}
					type='category'
					mirror
					tick={<CustomTick />}
					interval={0}
					// hide
				/>
			</BarChart>
		</ResponsiveContainer>
	);
}
